<template>
  <div>
    <div class="flex flex-wrap gap-5 mb-4">
      <div class="flex gap-4 items-center flex-wrap">
        <h2 class="text-2xl font-bold">All Industries</h2>
        <div
          class="text-sm text-gray-700 cursor-pointer"
          @click="$refs.createIndustry.open()"
        >
          + Add New Industry
        </div>
      </div>
      <div class="ml-auto">
        <button class="btn btn-blue" @click="beginSorting">
          Reorder
        </button>
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :data="industries.data"
            :columns="industries.columns"
            :query="industries.searchQuery"
            :loading="industries.loading"
            :actions="industries.actions"
            :fillable="false"
            ref="table"
          >
            <template #header>
              <div class="pt-4">
                <search-form
                  placeholder="Search users names, emails, phone numbers, bvn, etc."
                  class="mb-5"
                  v-model="industries.searchQuery"
                />
              </div>
            </template>
          </datatable>
        </div>
      </div>
    </div>

    <create-industry ref="createIndustry" />

    <update-industry
      v-if="industries.selectedIndustry"
      :industry="industries.selectedIndustry"
      :key="componentKey"
    />

    <modal className="w-750px" ref="sortModal" @close="handleClose">
      <div class="flex flex-col gap-5">
        <h5>Reorder Industries</h5>
        <div class="border border-gray-500">
          <draggable
            v-model="industries.sortingArray"
            :animation="200"
            handle=".handle"
            ghost-class="ghost"
            :disabled="false"
          >
            <transition-group type="transition" name="flip-list">
              <template v-for="(industry, index) in industries.sortingArray">
                <div
                  class="bg-white border-b border-gray-500 flex"
                  :key="index"
                >
                  <div
                    class="cursor-move flex handle items-center px-5 text-gray-500 text-lg"
                  >
                    <ion-icon name="reorder-three-outline" />
                  </div>
                  <div
                    class="border-l border-r border-gray-500 flex-grow font-medium px-4 py-3"
                  >
                    {{ industry.name }}
                  </div>
                  <div
                    class="flex items-center justify-center text-gray-500 text-xs w-10"
                  >
                    {{ index + 1 }}
                  </div>
                </div>
              </template>
            </transition-group>
          </draggable>
        </div>
        <div class="flex gap-4 items-center">
          <button class="btn btn-gray" @click="reset" :disabled="form.loading">
            Reset
          </button>
          <button
            class="btn btn-blue"
            @click="saveChanges"
            :disabled="form.loading"
          >
            <sm-loader-white v-if="form.loading" />
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  components: {
    UpdateIndustry: () => import('./components/UpdateIndustry'),
    CreateIndustry: () => import('./components/CreateIndustry'),
  },
  data() {
    return {
      form: this.$options.basicForm(),
      industries: this.$options.resource([], {
        searchQuery: '',
        columns: [
          {
            name: 'name',
            th: 'name',
          },
          {
            name: 'merchants',
            th: 'Merchants',
            render: (industry) => industry?.merchants?.length,
          },
          {
            name: 'featured',
            th: 'Featured',
            render: (industry) => (industry?.featured === 1 ? 'Yes' : 'No'),
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (industry, created_at) => {
              return this.$options.filters.dateFormat(created_at, 'M dd, Y');
            },
          },
        ],
        actions: [
          {
            text: 'Edit Industry',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.editIndustry,
          },
        ],
        sortingArray: [],
        selectedIndustry: null,
      }),
      componentKey: 0,
    };
  },
  beforeMount() {
    this.getIndustries();
  },
  methods: {
    async getIndustries() {
      this.industries.loading = true;
      await this.sendRequest('admin.merchants.category', {
        success: (response) => {
          this.industries.data = response.data.data;
          this.reset();
        },
        error: (error) => console.log(error),
      });
      this.industries.loading = false;
    },
    async saveChanges() {
      const ids = this.industries.sortingArray.map((industry) => industry.id);
      this.form.loading = true;
      await this.sendRequest('admin.merchants.reorderIndustries', {
        data: {
          ids,
        },
        success: () => {
          this.getIndustries();
          this.$success({
            title: "Operation Successful!",
            body: "Industry priority updated",
            button: "Okay"
          });
          this.$refs.sortModal.close();
        },
        error: (error) => console.log(error),
      });
      this.form.loading = false;
    },
    beginSorting() {
      this.$refs.sortModal.open();
    },
    editIndustry(industry) {
      this.industries.selectedIndustry = { ...industry };
      this.$nextTick(() => {
        this.componentKey += 1;
      });
    },
    handleClose() {
      if (this.form.loading) {
        this.beginSorting();
      }
    },
    reset() {
      this.industries.sortingArray = this.industries.data;
    },
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
